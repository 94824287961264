<template>
  <div>
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    
    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader v-if="!isEmbedded" :event="event"/>

      <div v-if="photos && photos.length > 0">
        <v-card-title v-if="!isEmbedded" class="subtitle">{{$t('events.photos')}}</v-card-title>
        <PhotoGallery :photos="photos" :eventId="event.id" />
      </div>
      
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import ActivityUploadForm from '@/components/ActivityUploadForm.vue';
import EventHeader from '@/components/EventHeader.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'
import PhotoGallery from "@/components/PhotoGallery";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Event",
  components: {
    VueMarkdown,
    EventHeader,
    PhotoGallery,
  },
  props: {
  },
  data() {
    return {
      event: null,
      photos: null,
    };
  },
  methods: {
      
    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
      this.photos = (await eventService.getPhotos(id)).data.data;
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.photos'), disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
    ...mapGetters({
      user: "user"
    })
  },
  async mounted() {
    await this.loadEvent(this.$route.params.id);
    
    EventBus.$on('login-state-change', async user => {
      //console.log('LOGIN CHANGED', user, this);
      await this.getEventStatus();
    });
  }
};
</script>
<style lang="scss">

</style>

